import React, { useEffect } from "react";
import { useNavigate ,Outlet} from "react-router-dom";
import { ILayout } from "../interface";
import { useAppSelector } from 'common/hooks/redux'
import { RootState } from "store";

const UnProtectedLayout: React.FC<ILayout> = (): React.ReactElement => {
  const {user} = useAppSelector((state: RootState) => state.auth)
const navigate = useNavigate()
  useEffect(() => {
    if (user) {
      navigate('/')
    }
  }, [user]);

  return (
    <Outlet />
  );
};

export default UnProtectedLayout;
