import ReactDOM from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
// import { Provider } from "react-redux"
import App from "./App";
import "./index.css";
import { store } from "./store";
// import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider } from "wagmi";
import { config } from "./config";

// const queryClient = new QueryClient()

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
	<ReduxProvider store={store}>
		{/* <WagmiProvider config={config}>
  <QueryClientProvider client={queryClient}> 
     */}
		<App />

		{/* </QueryClientProvider> 
    </WagmiProvider> */}
	</ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
