import { UserRoleType } from "api/interface";
import { UnknownObject } from "common/type";
// import { getLocalStorageItem, i18n, initWindowFunc } from "core";
import _ from "lodash";
import moment from "moment";
import { NETWORKS } from "common/constants";

export const scrollTop = (): void => {
	window.scrollTo(0, 0);
};

export const removeBodyScroll = (): void => {
	document.body.style.overflow = "hidden";
};

export const revertBodyScroll = (): void => {
	document.body.style.overflow = "";
};

export const validationObject = (object: UnknownObject): boolean => {
	const keys: string[] = Object.keys(object);

	if (_.isEmpty(keys)) return false;

	return keys.every((key: string) => {
		return !_.isUndefined(object[key]) && !_.isEmpty(object[key]);
	});
};

export const isUser = (role: UserRoleType): boolean => role === "user";

export const isAdmin = (role: UserRoleType): boolean => role === "admin";

export const setDefaultLanguage = (): void => {
	// const localStorageLng = getLocalStorageItem("lng");
	// if (!_.isNull(localStorageLng) && localStorageLng !== i18n.language) {
	// 	i18n.changeLanguage(localStorageLng);
	// }
};

export const setWindowFunction = ({
	initUserInfoAction,
	showModalAction,
}: {
	initUserInfoAction: Function;
	showModalAction: Function;
}): void => {
	// initWindowFunc({
	// 	initUserInfoAction,
	// 	showModalAction,
	// });
};

export async function authHeader() {
	const token = await localStorage.getItem("accessToken");
	return `Bearer ${token}`;
}

export function assetTypeIcon(mimetype?: string) {
	// text/csv
	// application/msword
	// image/jpeg
	//  audio/mpeg
	//  video/mp4

	if (!mimetype) return "image.svg";
	const mimeTypeArr = mimetype.split("/");
	return mimeTypeArr[0] + ".svg";
}

export function assetPreview(url?: string, mimetype?: string) {
	let prevImg = "assets/images/filetypes/";
	if (!mimetype) return prevImg;
	const mimeTypeArr = mimetype.split("/");
	if (mimeTypeArr[0] && mimeTypeArr[0] !== "image") {
		return prevImg + mimeTypeArr[0] + ".svg";
	}
	return url;
}

export function reArangeFilters(filters = {}) {
	let queryString = "?";
	for (const [key, value] of Object.entries(filters)) {
		if (Array.isArray(value)) {
			for (let item of value) {
				queryString = `${queryString}${key}=${item}&`;
			}
		} else {
			queryString = `${queryString}${key}=${value}&`;
		}
	}
	return queryString !== "?" ? queryString : "";
}

export function getCenterShort(text: string | undefined, lettersLength = 6) {
	return (
		text?.slice(0, lettersLength) +
		"..." +
		text?.slice(text.length - lettersLength)
	);
}

export function calculateFileSize(sizeInBytes: number) {
	if (!sizeInBytes) return 0;
	const sizeTypes = ["Bytes", "KB", "MB", "GB"];
	let fSize = sizeInBytes;
	let i = 0;
	while (fSize > 900) {
		fSize /= 1024;
		i++;
	}
	return Math.round(fSize * 100) / 100 + " " + sizeTypes[i];
}

export async function downloadAsset(
	src: string,
	mimetype: string,
	title: string
) {
	const url = src;
	const a = document.createElement("a");
	a.href = url;
	a.download = title || "download";
	const clickHandler = () => {
		setTimeout(() => {
			URL.revokeObjectURL(url);
			document.removeEventListener("click", clickHandler);
		}, 150);
	};
	a.addEventListener("click", clickHandler, false);
	a.click();

	return a;

	// const response = await fetch("https://cors-anywhere.herokuapp.com/" + src);
	// const blob = await response.blob();
	// const link = document.createElement("a");
	// link.href = URL.createObjectURL(blob);
	// link.download = title;
	// document.body.appendChild(link);
	// link.click();
	// document.body.removeChild(link);
}

export function handleCheckStatus(
	expDate: string | undefined,
	status: string | undefined
) {
	const currentDate = moment(new Date());
	const expiryDate = moment(expDate);

	if (!moment(expiryDate).isAfter(currentDate) && status == "PENDING") {
		status = "EXPIRED";
	}
	return status;
}

export function validateEmail(email: string) {
	// Regular expression for email validation
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return emailRegex.test(email);
}

export function getNFTURL(tokenId: number, networkId: number) {
	const network = NETWORKS[networkId];
	if (!network) return undefined;
	return `${network.blockExplorerUrl}token/${network.contractAddress}?a=${tokenId}`;
}

export function sortedDataList(array: Array<any>) {
	return array.slice().sort((a, b) => {
		const lastNameA = a.contributor?.last_name?.toLowerCase() ?? "";
		const lastNameB = b.contributor?.last_name?.toLowerCase() ?? "";
		const firstNameA = a.contributor?.first_name?.toLowerCase() ?? "";
		const firstNameB = b.contributor?.first_name?.toLowerCase() ?? "";

		if (lastNameA < lastNameB) return -1;
		if (lastNameA > lastNameB) return 1;
		if (firstNameA < firstNameB) return -1;
		if (firstNameA > firstNameB) return 1;
		return 0;
	});
}

export function updateFavicon(count: number) {
	const favicon = document.getElementById("favicon") as HTMLLinkElement;
	if (!favicon) return;

	const faviconUrl = favicon.href;
	const canvas = document.createElement("canvas");
	const ctx = canvas.getContext("2d");

	const size = 32;
	canvas.width = size;
	canvas.height = size;

	const img = new Image();
	img.src = faviconUrl;

	img.onload = () => {
		if (!ctx) return;

		// Draw the original favicon
		ctx.drawImage(img, 0, 0, size, size);

		if (count > 0) {
			// Draw the notification circle
			ctx.beginPath();
			ctx.arc(size - 8, 8, 8, 0, 2 * Math.PI);
			ctx.fillStyle = "#ff0000";
			ctx.fill();

			// Draw the count number
			ctx.fillStyle = "#fff";
			ctx.font = "10px Arial";
			ctx.textAlign = "center";
			ctx.textBaseline = "middle";
			ctx.fillText(count.toString(), size - 8, 8);
		}

		favicon.href = canvas.toDataURL("image/png");
	};
}

export function updateTitleNotification(count: number) {
	if (count > 0) {
		document.title = `(${count}) OnRekord`;
	} else {
		document.title = "OnRekord";
	}
}

export function getHeadingText(path: string): string {
	switch (true) {
		case path.includes("/super-nft"):
			return "SuperNFT Dashboard";
		case path.includes("/nftlibrary"):
			return "SuperNFT Library";
		case path.includes("/collection-library"):
			return "Collection Library";
		case path.includes("/admin"):
			return "Admin Analysis";
		case path.includes("/my-files"):
			return "My Files";
		case path.includes("/contributors"):
			return "Contributors";
		case path.includes("/contributions"):
			return "Contributions";
		case path.includes("/recent-files"):
			return "Recent Files";
		case path.includes("-profile"):
			return "Profile";
		case path.includes("-notifications"):
			return "Notifications";
		case path.includes("-identity-verification"):
			return "Verification";
		default:
			return "Dashboard";
	}
}
