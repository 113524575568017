import { AuthStoreType } from "store/type";

export const initGlobalState: AuthStoreType = {
	user: null,
	isUserSideBarAdmin: false,
	updateUserLoading: false,
	signInLoading: false,
	verifyOTPLoading: false,
	authenticationLoading: true,
	updateUserContrBtnLoading: false,
	logOutModal: false,
	onRecordWalletStep: 0,
	getRetrieveKeyLoading: false,
	validateRetrieveKeyOTPLoading: false,
	secretKey: null,
};
